import React from "react";
import "./Fourth.css";
import vector from "../../../assets/images/Vector.png";
import leaf1 from "../../../assets/images/L1.png";
import leaf2 from "../../../assets/images/L2.png";
import leaf3 from "../../../assets/images/L3.png";
import leaf4 from "../../../assets/images/L4.png";
import Tick from "../../../assets/images/Icon.png";
import { AiOutlineCheck } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";

const Fourth = () => {
  return (
    <>
      <div>
        <center>
          <h1 className="fourth-heading mt-20">Pricing</h1>
          <p className="fourth-text">
            Start monitoring for free and then choose your plan.
          </p>
          <img src={vector} className="vect" />
        </center>
        <section className="F-main-sec w-[80%] mx-[10%] flex mt-6">
          <section className="F-left-section flex">
            <div className="f-card">
              <div className="flex firstcard">
                <h1 className="fourth-text">Free trial</h1>
                <sup>
                  <img src={leaf1} className="leaf" />
                </sup>
              </div>
              <div className="pricediv">
                <span className="inr">INR</span>
                <span className="price">12</span>
                <span className="fourth-text">/Month</span>
              </div>
              <div className="content">
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Perspiciatis unde omnis iste{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Accusantium doloremque{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Aspernatur aut odit aut fugit{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Neque porro quisquam est{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Exercitationem ullam corporis sus{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Illum qui dolorem eum fugiat quo{" "}
                  </span>
                </div>
              </div>
              <button className="f-card-button flex pt-[0.40rem] mt-[6.5rem] mb-4">
                Buy now{" "}
                <span className="mt-1 ml-2">
                  <sub>
                    <BsArrowRight />
                  </sub>
                </span>{" "}
              </button>
            </div>

            <div className="f-card">
              <div className="flex firstcard">
                <h1 className="fourth-text">Silver</h1>
                <sup>
                  <img src={leaf2} className="leaf2" />
                </sup>
              </div>
              <div className="pricediv">
                <span className="inr">INR</span>
                <span className="price">12</span>
                <span className="fourth-text">/Month</span>
              </div>
              <div className="content">
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Perspiciatis unde omnis iste{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Accusantium doloremque{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Aspernatur aut odit aut fugit{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Neque porro quisquam est{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Exercitationem ullam corporis sus{" "}
                  </span>
                </div>
              </div>
              <button className="f-card-button flex  pt-[0.40rem] mt-[8.5rem] mb-4">
                Buy now{" "}
                <span className="mt-1 ml-2">
                  <sub>
                    <BsArrowRight />
                  </sub>
                </span>{" "}
              </button>
            </div>
          </section>

          <section className="F-right-section flex">
            <div className="f-card">
              <div className="flex firstcard">
                <h1 className="fourth-text">Gold</h1>
                <sup>
                  <img src={leaf3} className="leaf3" />
                </sup>
              </div>
              <div className="pricediv">
                <span className="inr">INR</span>
                <span className="price">12</span>
                <span className="fourth-text">/Month</span>
              </div>
              <div className="content">
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Perspiciatis unde omnis iste{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Accusantium doloremque{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Aspernatur aut odit aut fugit{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Neque porro quisquam est{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Exercitationem ullam corporis sus{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Illum qui dolorem eum fugiat quo{" "}
                  </span>
                </div>
              </div>
              <button className="f-card-button flex  pt-[0.40rem] mt-[6.5rem] mb-4">
                Buy now{" "}
                <span className="mt-1 ml-2">
                  <sub>
                    <BsArrowRight />
                  </sub>
                </span>{" "}
              </button>
            </div>

            <div className="f-card">
              <div className="flex firstcard">
                <h1 className="fourth-text">Platinum</h1>
                <sup>
                  <img src={leaf1} className="leaf" />
                </sup>
              </div>
              <div className="pricediv">
                <span className="inr">INR</span>
                <span className="price">12</span>
                <span className="fourth-text">/Month</span>
              </div>
              <div className="content">
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Perspiciatis unde omnis iste{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Accusantium doloremque{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Aspernatur aut odit aut fugit{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Neque porro quisquam est{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Exercitationem ullam corporis sus{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Illum qui dolorem eum fugiat quo{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Accusantium doloremque{" "}
                  </span>
                </div>
                <div className="flex ab ml-4">
                  <span className="mt-[2px]">
                    <AiOutlineCheck />
                  </span>
                  <span className="ml-2 text-[0.8rem]">
                    Neque porro quisquam est{" "}
                  </span>
                </div>
              </div>
              <button className="f-card-button flex  pt-[0.40rem] mt-10 mb-4">
                Buy now{" "}
                <span className="mt-1 ml-2">
                  <sub>
                    <BsArrowRight />
                  </sub>
                </span>{" "}
              </button>
            </div>
          </section>
        </section>
        <div></div>
      </div>
    </>
  );
};

export default Fourth;
