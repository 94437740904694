import React from 'react';
import css from "./Pricing.module.scss"

const Pricing = () => {
  return (
    <div className='mt-20'>
         <div className={css.container}>
            <center>
                <h1 className={css.heading}>
Our Subscription plans Coming Soon
                </h1>
                
            </center>
        </div>
    </div>
  )
}

export default Pricing