import React, { useState } from "react";
import "./contactform.css";
import { FaUser, FaEnvelope, FaPhone, FaFileAlt } from "react-icons/fa";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined, // Remove the error for the field
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form inputs
    const errors = {};
    if (formData.name.trim() === "") {
      errors.name = "Name is required";
    }
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (formData.phoneNumber.trim() === "") {
      errors.phoneNumber = "Phone number is required";
    }
    if (formData.description.trim() === "") {
      errors.description = "Description is required";
    }

    if (Object.keys(errors).length === 0) {
      // Submit the form data
      // Replace 'your-submit-endpoint' with the actual server-side script endpoint
      fetch("your-submit-endpoint", {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the server
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div>
        <label>
          <FaUser className="form-icon" />
          <input
            className="form-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </label>
        {errors.name && <span className="error">{errors.name}</span>}
      </div>

      <div className="c-f-flex-div">
        <div className="contact-flex-item">
          <label>
            <FaEnvelope className="form-icon" />
            <input
              className="form-input4"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </label>
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="contact-flex-item">
          <label>
            <FaPhone className="form-icon2" />
            <input
              className="form-input2"
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
            />
          </label>
          {errors.phoneNumber && (
            <span className="error">{errors.phoneNumber}</span>
          )}
        </div>
      </div>

      <div>
        <label>
          <FaFileAlt className="form-icon" />
          <textarea
            className="form-input3"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
          />
        </label>
        {errors.description && (
          <span className="error">{errors.description}</span>
        )}
      </div>
      <div>
        <button className="submit-b" type="submit">
          Send Message
        </button>
      </div>
    </form>
  );
};

export default ContactForm;





// import React, { useState } from "react";
// import "./contactform.css";
// import { FaUser, FaEnvelope, FaPhone, FaFileAlt } from "react-icons/fa";

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phoneNumber: "",
//     description: "",
//   });

//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Validate the form inputs
//     const errors = {};
//     if (formData.name.trim() === "") {
//       errors.name = "Name is required";
//     }
//     if (formData.email.trim() === "") {
//       errors.email = "Email is required";
//     } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
//       errors.email = "Invalid email format";
//     }
//     if (formData.phoneNumber.trim() === "") {
//       errors.phoneNumber = "Phone number is required";
//     }
//     if (formData.description.trim() === "") {
//       errors.description = "Description is required";
//     }

//     if (Object.keys(errors).length === 0) {
//       // Submit the form data
//       // Replace 'your-submit-endpoint' with the actual server-side script endpoint
//       fetch("your-submit-endpoint", {
//         method: "POST",
//         body: JSON.stringify(formData),
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           // Handle the response from the server
//           console.log(data);
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//         });
//     } else {
//       setErrors(errors);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className="contact-form">
//       <div>
//         <label>
//           <FaUser className="form-icon" />
//           <input
//             className="form-input"
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             placeholder="Name"
//           />
//         </label>
//         {errors.name && <span className="error">{errors.name}</span>}
//       </div>

//       <div className="c-f-flex-div">
//         <div className="contact-flex-item">
//           <label>
//             <FaEnvelope className="form-icon" />
//             <input
//               className="form-input4"
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               placeholder="Email"
//             />
//           </label>
//           {errors.email && <span className="error">{errors.email}</span>}
//         </div>
//         <div className="contact-flex-item">
//           <label>
//             <FaPhone className="form-icon2" />
//             <input
//               className="form-input2"
//               type="text"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               placeholder="Phone Number"
//             />
//           </label>
//           {errors.phoneNumber && (
//             <span className="error">{errors.phoneNumber}</span>
//           )}
//         </div>
//       </div>

//       <div>
//         <label>
//           <FaFileAlt className="form-icon" />
//           <textarea
//             className="form-input3"
//             name="description"
//             value={formData.description}
//             onChange={handleChange}
//             placeholder="Description"
//           />
//         </label>
//         {errors.description && (
//           <span className="error">{errors.description}</span>
//         )}
//       </div>
//       <div>
//         <button className="submit-b" type="submit">
//           Send Message
//         </button>
//       </div>
//     </form>
//   );
// };

// export default ContactForm;
