import React from "react";
import { useState } from "react";
import Logo from "../../assets/images/Logo header.png";
import { Link, useLocation } from "react-router-dom";


const Navbar = () => {
  let Links = [
    { name: "Home", link: "/" },
    { name: "Pricing", link: "/pricing" },
    { name: "Blog", link: "/blog" },
    { name: "Contact", link: "/contact" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    { name: "                           ", link: "" },
    
  ];

  let [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="z-[1000] shadow-md-white w-full fixed top-0 left-0 headermain shadow-sm">
      <div className="md:flex bg-white items-center justify-between py-4 md:px-10 px-7">
        <div className="cursor-pointer flex items-center">
          <a href="/">
          <img src={Logo} className="md:ml-12" alt="Logo" />
          </a>
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <ion-icon
            className="text-black bg-black"
            name={open ? "close" : "menu"}
          ></ion-icon>
        </div>
        <ul
          className={`md:ml-40 md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-20 opacity-100" : "top-[-490px]"
          } md:opacity-100 opacity-0`}
        >
          {Links.map((link) => (
            <li
              key={link.name}
              className="md:ml-8 text-[1rem] md:my-0 my-7 text-[urbanist]"
            >
              <Link
                to={link.link}
                className={`hover:text-[#5D7202] duration-500 ${
                  location.pathname === link.link
                    ? "text-[#5D7202] "
                    : "text-[#B4BA3D]"
                }`}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Navbar;



// const Navbar = () => {

//     let Links=[
//         {name:"Home",link:"/home"},
//         {name:"About",link:"/skills"},
//         {name:"Feature",link:"/services"},
//         {name:"Pricing",link:"/experience"},
//         {name:"Blog",link:"/blog"},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},
//         {name:"    ",link:""},

//         {name:"Contact",link:"/contact"},
//     ];

//     let[open,setOpen]=useState(false)

//   return (
//     <div className='z-[1000] shadow-md-white w-full fixed top-0 left-0'>
//         <div className='md:flex bg-white  items-center justify-between py-4 md:px-10 px-7'>
//             <div className='  cursor-pointer flex items-center'>
// <img src={Logo}/>
//             </div>
//             <div onClick={()=>setOpen(!open)} className=' text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
//             <ion-icon className="text-black bg-black" name={open? 'close':'menu'}></ion-icon>
//             </div>
//             <ul className={`md:ml-40 md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open?'top-20 opacity-100' :'top-[-490px]'} md:opacity-100 opacity-0`}>
// {
//     Links.map((link)=>(
//         <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7'>
//             <a href={link.link} className=' hover:text-[#5D7202] duration-500 text-[#B4BA3D]'>{link.name}</a>
//         </li>
//     ))
// }
//             </ul>
//         </div>
//     </div>
//   )
// }

