// import React from 'react';
// import "./Second.css";
// import image from "../../../assets/images/H-Second.png"

// const Second = () => {
//   return (
//     <div>Second
//         <div className='second-main'>
//            <center>
//             <section className='second-heading'>Empower your hospitality operations with advanced cloud technology.</section>
//             <img src={image} className='w-[80%]'/>
//             </center>

//         </div>
//     </div>
//   )
// }

// export default Second
import React from "react";
import css from "./Second.module.scss";
import image from "../../../assets/images/H-Second.png";

const Second = () => {
  return (
    <div className={css.maindiv}>
      <div className={css.secondmain}>
        <center>
          <section className={css["second-heading"]}>
            Empower your hospitality operations with advanced cloud technology.
          </section>
          <img src={image} className={css.image} alt="Second Image" />
        </center>
      </div>
    </div>
  );
};
export default Second;
