import React from 'react';
import css from "./First.module.scss"

import dots from "../../../assets/images/Patten.png"
import vlines from "../../../assets/images/Group 32700.png"
import play from "../../../assets/images/Group 32710.png"
import hlines from "../../../assets/images/Group 32701.png"
import rect from "../../../assets/images/Rectangle.png"
import dash from "../../../assets/images/dashboard.png"


const First = () => {
  return (
    <div className='mt-20'>
      <div className={css.container}>
        <section className={css.secleft}>
          <div className={css.fleximagediv}>
            <img className='h-8  ' src={dots} alt="" />
            <img className="ml-20 mt-4 sm:ml-40 md:ml-80" src={vlines} alt="" />
          </div>
          <div className={css.maincontent}>
            <section className={css.heading}>
            The ultimate solution for modern wellness management.
            </section>
            <section className={css.text}>
            Simplify. Streamline. Succeed.
            </section>
          </div>
          <div className={css.fleximagediv}>
            <a href='/contact'>
            <button className='bg-[#6c7d02] text-white w-[150px] h-12 rounded-lg mt-3 hover:bg-[#9cad2c] '>Request a demo</button>
            </a>
            <img className="mt-2 ml-4 w-[50px] " src={play} alt="" />
          </div>
<img src={hlines} className='mt-16 ml-[15%] mb-24' alt="" />
        </section>
        <section className={css.secright}>
          <img src={rect} className={css.lower} alt="" />
          <img src={dash} className={css.upper} alt="" />
        </section>
      </div>
      
    </div>
  )
}

export default First