import { configureStore } from "@reduxjs/toolkit";

import { socialMediaApiReducer, contactApiReducer , blogApiReducer, authReducer, authPageReducer, showCartReducer } from "./slices";

export default configureStore({
    reducer: {
        contactApi: contactApiReducer,
        showAuth: authReducer,
        authPage: authPageReducer,
        blogApi: blogApiReducer,
    }
})