import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import api from '../api';

const { REACT_APP_API_BASE_URL: apiBaseURL } = process.env;



const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: false,
    },
    reducers: {
        showAuth: (state, action) => {
            state.value = action.payload;
        }
    }
});

const authPageSlice = createSlice({
    name: 'authPage',
    initialState: {
        value: 'login',
    },
    reducers: {
        authPage: (state, action) => {
            state.value = action.payload;
        }
    }
});





const contactApiSlice = createSlice({
    name: 'contactApi',
    initialState: {
        value: null
    },
    reducers: {
        contactApi: (state, action) => {
            state.value = action.payload;
        },
    },
});

const blogApiSlice = createSlice({
    name: 'blogApi',
    initialState: {
        value: null
    },
    reducers: {
        blogApi: (state, action) => {
            state.value = action.payload;
        },
    },
});





const { blogApi } = blogApiSlice.actions;
export const { showAuth } = authSlice.actions;
export const { authPage } = authPageSlice.actions;


const { contactApi } = contactApiSlice.actions;



export const contactApiReducer = contactApiSlice.reducer;
export const blogApiReducer = blogApiSlice.reducer;
export const authReducer = authSlice.reducer;
export const authPageReducer = authPageSlice.reducer;









export const fetchContactApi = () => {
    return async function fetchHomeApiThunk(dispatch) {
        try {
            let data = await api.get('/contactus');
            data = data.data;
            dispatch(contactApi(data));
        }
        catch (err) {
            console.log(err);
        }
    }
};

export const fetchBlogApi = () => {
    return async function fetchHomeApiThunk(dispatch) {
        try {
            let data = await api.get('/blog/admin');
            data = data.data;
            dispatch(blogApi(data));
            console.log(data)

        }
        catch (err) {
            console.log(err);
        }
    }
};