import React, { useState, useEffect } from "react";
import { useCarousel } from "use-carousel-hook";
import images from "../../../assets/images/quotes.png";
import slider from "../../../assets/images/slider con.png";
import dot from "../../../assets/images/selips.png";
import "./Fifth.css";

import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

function Card(props) {
  return (
    <>
      <div className="slide-card-main">
        <img className="slide-card-img" src={props.img} />

        <p class="slide-card-text1">{props.text1}</p>
        <br />
        <br />
        <br />
        <p class="slide-card-text2">{props.text2}</p>
        <p class="slide-card-text3">{props.text3}</p>
      </div>
      <img src={dot} className="mt-[8vh] ml-4" />
    </>
  );
}

const Fifth = () => {
  const { ref, previous, next, setCurrent, reset } = useCarousel();

  const [autoSlide, setAutoSlide] = useState(true);

  useEffect(() => {
    let intervalId;

    if (autoSlide) {
      intervalId = setInterval(() => {
        next();
      }, 3000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSlide]);


  return (
    <>
      <div className="test-sec">
        <div className="test-title">
          <center>
            <h1 className="fourth-heading text-[#212121]">Testimonials</h1>
            <p className="fourth-text text-[#424242] mt-5">
              Start monitoring for free and then choose your plan.
            </p>
          </center>
        </div>

        <div className="test-main-div">
          <ul ref={ref} className="carousel__list8">
            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Dr. Sharan"
                text2="– Wellness Coach at Wellness First "
                text3="Half Olive has revolutionized the way we manage our wellness center. The cloud-based solution has provided us with the flexibility to access our data from anywhere, making it easy to stay on top of our schedules and guest information. The analytics feature has also been incredibly helpful in identifying areas for improvement in our operations. I highly recommend Half Olive to anyone in the wellness industry looking to streamline their business.  "
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Micheal"
                text2="– Owner of Atmanand Wellness Center"
                text3="Since implementing Half Olive into our wellness center, we have seen a significant increase in productivity and efficiency. The platform is user-friendly and easy to navigate, making it simple for our team to manage guest information, schedules, and payments. Kudos to Half Olive !"
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Arun S."
                text2="– Manager of Wellness Services at Sunshine Wellness."
                text3="Half Olive's auto-scheduling feature is a game-changer for our business. It has significantly reduced the time and effort required to manage our schedules, enabling us to focus on providing exceptional wellness services to our clients. This top feature has really eased our day-to-day operations and has provided us with the flexibility to customize our schedules to fit our clients' needs. - Micheal, "
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Chaitali"
                text2="– Director of Operations at Yaani Wellness Center"
                text3="Half Olive has been a game-changer for our business. The platform has streamlined our processes, enabling us to focus on what we do best - providing top-notch wellness services to our clients. The customer support team at Half Olive is exceptional, and they are always available to answer any questions we have. Thank you, Half Olive, for helping us take our business to the next level! - Chaitali, "
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Dr. Sharan"
                text2="– Wellness Coach at Wellness First "
                text3="Half Olive has revolutionized the way we manage our wellness center. The cloud-based solution has provided us with the flexibility to access our data from anywhere, making it easy to stay on top of our schedules and guest information. The analytics feature has also been incredibly helpful in identifying areas for improvement in our operations. I highly recommend Half Olive to anyone in the wellness industry looking to streamline their business.  "
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Micheal"
                text2="– Owner of Atmanand Wellness Center"
                text3="Since implementing Half Olive into our wellness center, we have seen a significant increase in productivity and efficiency. The platform is user-friendly and easy to navigate, making it simple for our team to manage guest information, schedules, and payments. Kudos to Half Olive !"
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Arun S."
                text2="– Manager of Wellness Services at Sunshine Wellness."
                text3="Half Olive's auto-scheduling feature is a game-changer for our business. It has significantly reduced the time and effort required to manage our schedules, enabling us to focus on providing exceptional wellness services to our clients. This top feature has really eased our day-to-day operations and has provided us with the flexibility to customize our schedules to fit our clients' needs. - Micheal, "
              />
            </li>

            <li class="carousel__item8">
              <Card
                num="01"
                img={images}
                text1="Chaitali"
                text2="– Director of Operations at Yaani Wellness Center"
                text3="Half Olive has been a game-changer for our business. The platform has streamlined our processes, enabling us to focus on what we do best - providing top-notch wellness services to our clients. The customer support team at Half Olive is exceptional, and they are always available to answer any questions we have. Thank you, Half Olive, for helping us take our business to the next level! - Chaitali, "
              />
            </li>
          </ul>
        </div>
        <hr className="font-bold border-solid border-1 border-[#B4BA3D] mt-[-4vh]" />
        <div className="test-btn-div">
          <center className="flex justify-center mt-10">
            <button
              className="prev-btn"
              onClick={() => previous()}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <BsArrowLeft className="font-bold text-gray-500 border-2 text-2xl rounded-full" />
            </button>
            <div className="mt-2 mx-3">
              <img src={slider} />
            </div>
            <button
              className="next-btn"
              onClick={() => next()}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <BsArrowRight className="font-bold text-gray-500 border-2 text-2xl rounded-full" />
            </button>
          </center>
        </div>
      </div>
    </>
  );
};

export default Fifth;
