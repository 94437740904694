import React from 'react'
import First from '../../components/Home/First/First'
import Second from '../../components/Home/Second/Second'
import Third from '../../components/Home/Third/Third'
import Fourth from '../../components/Home/Fourth/Fourth'
import Fifth from '../../components/Home/Fifth/Fifth'

const Home = () => {
  return (
    <div>
      <First/>
      <Second/>
      <Third/>
      <Fourth/>
      <Fifth/>
    </div>
  )
}

export default Home