import React, { useEffect, useState } from 'react';
import css from "./Blog.module.scss";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import demo from "../../assets/images/H-Second.png"

import { useSelector, useDispatch } from 'react-redux';
import { fetchBlogApi } from '../../redux/slices';

const CategorySelector = ({ categories, selectedCategory, onCategoryChange }) => {
  return (
    <div className={css.categorySelector}>
      <h2>Select a Category:</h2>
      <ul>
        <li
          className={!selectedCategory ? css.selected : ''}
          onClick={() => onCategoryChange(null)}
        >
          All Categories
        </li>
        {categories.map(category => (
          <li
            key={category}
            className={selectedCategory === category ? css.selected : ''}
            onClick={() => onCategoryChange(category)}
          >
            {category}
          </li>
        ))}
      </ul>
    </div>
  );
};


const RecentBlogs = ({ blogs }) => {



  
  const recentBlogs = blogs.slice(0, 5); // Get the first 5 blogs

  return (
    <div className={css.recent}>
      <h2 className={css.recentheading}>Recent Posts</h2>
      {recentBlogs.map((blog) => (
        <div className={css.mainrecent} key={blog.id}>
            <a href={`/blog/${encodeURIComponent(blog.title)}`}>
          <h3 className={css.title}>{blog.title}</h3></a>
          <p className={css.date}>{blog.createddate}</p>
        </div>
      ))}
    </div>
  );
};

// fetch("http://localhost:8000/blog/admin").then((resp)=>{
//   resp.json().then((resl)=>{
//     console.log(resl)
//   })
// })


const Blog = ({ post }) => {


  const dispatch = useDispatch();
  let blog = useSelector((state) => state.blogApi.value);
  if(blog){
    blog = blog.data;
  }
  const [data, setData] = useState({
    loading: true,
    blogs: [],
  });

  useEffect(() => {
    dispatch(fetchBlogApi())
      .then(() => {
        setData({
          loading: false,
          blogs: blog,
        });
      })
      .catch((error) => {
        console.error('Error fetching blogs:', error);
        setData({
          loading: false,
          blogs: [],
        });
      });
  }, []);

  useEffect(()=>{
    console.log(blog);
  }, [blog]);

console.log(data)

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const blogsPerPage = 6;

  const blogs = [
    {
      id: 1,
      title: 'Blog 1 THIS IS BLOG OF REV',
      content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
      image:demo ,
      category: 'React',
      createddate: 'Oct 15,2023',
    },
    {
      id: 2,
      title: 'Blog 2 THIS IS BLOG OF REV',
      content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
      image: demo,
      category: 'JavaScript',
      createddate: 'Oct 15,2023',
    },
    {
      id: 3,
      title: 'Blog 2 THIS IS BLOG OF REV',
      content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
      image: demo,
      category: 'JavaScript455',
      createddate: 'Oct 15,2023',
    },
    {
      id: 4,
      title: 'Blog 2 THIS IS BLOG OF REV',
      content: 'Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...',
      image: demo,
      category: 'JavaScript455',
      createddate: 'Oct 15,2023',
    },
    {
        id: 4,
        title: 'Blog 2 THIS IS BLOG OF REV',
        content: 'Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...',
        image: demo,
        category: 'JavaScript455',
        createddate: 'Oct 15,2023',
      }, {
        id: 4,
        title: 'Blog 2 THIS IS BLOG OF REV',
        content: 'Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...',
        image: demo,
        category: 'JavaScript455',
        createddate: 'Oct 15,2023',
      }, {
        id: 4,
        title: 'Blog 2 THIS IS BLOG OF REV',
        content: 'Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...',
        image: demo,
        category: 'JavaScript455',
        createddate: 'Oct 15,2023',
      },
    // Add more blogs here...
  ];

  const categories = [...new Set(blogs.map(blog => blog.category))];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page when category changes
  };

  let filteredBlogs = blogs;
  if (selectedCategory) {
    filteredBlogs = blogs.filter(blog => blog.category === selectedCategory);
  }

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  return (
    <>
    <div className={css.header}>Blogs</div>
    <div className={css.main}>
      <section className={css.rightcat}>  
      <CategorySelector
          categories={categories}
          selectedCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
        />
        
        <RecentBlogs blogs={blogs} />
        
        </section>
      <section className={css.blogs} >

      

        {currentBlogs.map((blog) => (
          <div key={blog.id} className={css.container}>
            <section className={css.section1}>
              <img src={blog.image} title='' alt='' />
              
            </section>
            <section className={css.section2}>
            <a href={`/blog/${encodeURIComponent(blog.title)}`}>
                <h1 className={css.h1}>{blog.title}</h1>
              </a>
              <h6 className='text-gray-500' >{blog.createddate}</h6>
              <p className={css.para}>{blog.content}</p>
            </section>
          </div>
        ))}

       
      </section>
      
      </div>
      <div className={css.pagination}>
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
                          <BsArrowLeft />

          </button>
          {Array(Math.ceil(filteredBlogs.length / blogsPerPage))
            .fill()
            .map((_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? css.active : ''}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          <button
            disabled={indexOfLastBlog >= filteredBlogs.length}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
                          <BsArrowRight />

          </button>
        </div>
    </>
  );
};

export default Blog;











// import React, { useEffect, useState } from 'react';
// import css from "./Blog.module.scss";
// import { BsArrowLeft } from "react-icons/bs";
// import { BsArrowRight } from "react-icons/bs";
// import demo from "../../assets/images/H-Second.png"

// import { useSelector, useDispatch } from 'react-redux';
// import { fetchBlogApi } from '../../redux/slices';

// const CategorySelector = ({ categories, selectedCategory, onCategoryChange }) => {
//   return (
//     <div className={css.categorySelector}>
//       <h2>Select a Category:</h2>
//       <ul>
//         <li
//           className={!selectedCategory ? css.selected : ''}
//           onClick={() => onCategoryChange(null)}
//         >
//           All Categories
//         </li>
//         {categories.map(category => (
//           <li
//             key={category}
//             className={selectedCategory === category ? css.selected : ''}
//             onClick={() => onCategoryChange(category)}
//           >
//             {category}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// const RecentBlogs = ({ blogs }) => {
//   const recentBlogs = blogs.slice(0, 5); // Get the first 5 blogs

//   return (
//     <div className={css.recent}>
//       <h2 className={css.recentheading}>Recent Posts</h2>
//       {recentBlogs.map((blog) => (
//         <div className={css.mainrecent} key={blog._id}>
//           <a href={`/blog/${encodeURIComponent(blog.title)}`}>
//             <h3 className={css.title}>{blog.title}</h3>
//           </a>
//           <p className={css.date}>{blog.date}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// const Blog = ({ post }) => {
//     const dispatch = useDispatch();
//   const blogData = useSelector((state) => state.blogApi.value);
//   const [data, setData] = useState({
//     loading: true,
//     blogs: [],
//   });

//   useEffect(() => {
//     dispatch(fetchBlogApi())
//       .then(() => {
//         const filteredBlogs = blogData ? blogData.data.filter(blog => blog.status !== 'draft') : [];
//         setData({
//           loading: false,
//           blogs: filteredBlogs,
//         });
//       })
//       .catch((error) => {
//         console.error('Error fetching blogs:', error);
//         setData({
//           loading: false,
//           blogs: [],
//         });
//       });
//   }, [dispatch]);

//   useEffect(() => {
//     console.log(blogData);
//   }, [blogData]);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const blogsPerPage = 6;

//   const blogs = data.blogs;

//   const categories = [...new Set(blogs.map(blog => blog.category))];

//   const handleCategoryChange = (category) => {
//     setSelectedCategory(category);
//     setCurrentPage(1); // Reset to first page when category changes
//   };

//   let filteredBlogs = data.blogs;
//   if (selectedCategory) {
//     filteredBlogs = data.blogs.filter(blog => blog.category === selectedCategory);
//   }

//   const indexOfLastBlog = currentPage * blogsPerPage;
//   const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
//   const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);


//   return (
//     <>
//       <div className={css.header}>Blogs</div>
//       <div className={css.main}>
//         <section className={css.rightcat}>
//           <CategorySelector
//             categories={categories}
//             selectedCategory={selectedCategory}
//             onCategoryChange={handleCategoryChange}
//           />
//           <RecentBlogs blogs={blogs} />
//         </section>
//         <section className={css.blogs}>
//           {currentBlogs.map((blog) => (
//             <div key={blog._id} className={css.container}>
//               <section className={css.section1}>
//                 <img src={blog.feature_image} title={blog.alt} alt={blog.alt} />
//               </section>
//               <section className={css.section2}>
//                 <a href={`/blog/${encodeURIComponent(blog.title)}`}>
//                   <h1 className={css.h1}>{blog.title}</h1>
//                 </a>
//                 <h6 className='text-gray-500'>{blog.date}</h6>
//                 <p className={css.para}>{blog.description}</p>
//               </section>
//             </div>
//           ))}
//         </section>
//       </div>
//       <div className={css.pagination}>
//         <button
//           disabled={currentPage === 1}
//           onClick={() => setCurrentPage(currentPage - 1)}
//         >
//           <BsArrowLeft />
//         </button>
//         {Array(Math.ceil(filteredBlogs.length / blogsPerPage))
//           .fill()
//           .map((_, index) => (
//             <button
//               key={index}
//               className={currentPage === index + 1 ? css.active : ''}
//               onClick={() => setCurrentPage(index + 1)}
//             >
//               {index + 1}
//             </button>
//           ))}
//         <button
//           disabled={indexOfLastBlog >= filteredBlogs.length}
//           onClick={() => setCurrentPage(currentPage + 1)}
//         >
//           <BsArrowRight />
//         </button>
//       </div>
//     </>
//   );
// };

// export default Blog;
























