// import React from "react";
// import Home from "./pages/home/Home";
// import Footer from "./common/footer/Footer";
// import Navbar from "./common/header/header";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Contact from "./pages/Contact/Contact";
// import BlogPage from "./pages/blog/Blog";
// import ScrollToTop from "./pages/Scrolltotop";
// import Bloginfo from "./pages/blog/Blog-info/Bloginfo";

// const App = () => {
//   return (
//     <div>
//       <Router>
//         <ScrollToTop/>
//       <Navbar />
//       <Routes>
//             <Route exact path="/" element={<Home />} />
//             <Route  path="/blog" element={<BlogPage/>} />
//             <Route path="/blog/:title" element={<Bloginfo/>} />
//             <Route  path="/contact" element={<Contact/>} />
//             <Route  path="/pricing" element={<></>} />
//             <Route  path="/about" element={<></>} />
            
//           </Routes>
//             <Footer />
//       </Router>
//     </div>
//   );
// };

// export default App;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux"; // Import Provider
import store from "./redux/store"; // Import your Redux store
import ScrollToTop from "./pages/Scrolltotop";
import Navbar from "./common/header/header";
import Home from "./pages/home/Home";
import Footer from "./common/footer/Footer";
import Contact from "./pages/Contact/Contact";
import BlogPage from "./pages/blog/Blog";
import Bloginfo from "./pages/blog/Blog-info/Bloginfo";
import Pricing from "./pages/aboutus/Pricing";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:title" element={<Bloginfo />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing/>} />
        </Routes>
        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
