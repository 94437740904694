import React from "react";
import "./Footer.css";
import { BsArrowRight } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import { RiYoutubeLine } from "react-icons/ri";

const Footer = () => {
  return (
    <div>
      <section>
        <div className="footer-upward-div">
          <section className="footer-flex-div">
            <section className="flex-left">
              Manage hospitality operations with powerful cloud technology
            </section>
            <section className="flex-right">
              <a href="/contact">
              <button className="footer-button flex">
                Get a free Demo
                <BsArrowRight className="mt-[0.25rem] ml-2" />
              </button>
              </a>
            </section>
          </section>
        </div>
      </section>

      <div className="footer-below-div">
        <center className="pb-4 center">
          <h1 className="footer-heading pt-9">Get your free account now</h1>
          <p className="footer-text">
            {" "}
            ducimus qui blanditiis praesentium vol mnuptatum deleniti atque
            corrupti quos dolores et quas molestias
          </p>
          <section className="flex-right">
            <button className="footer-button2 flex">
              Start free trial
              <BsArrowRight className="mt-[0.25rem] ml-2" />
            </button>
          </section>
          <hr className="text-gray-600 mt-20" />

          <div className="bottom-last  ">
            <section className="text-b">
              © HalfOlive,2023 All right reserved
            </section>
            <section className="bottom-last-right flex  gap-2">
              <a href="#">
                <FaFacebookF className="text-white pt-4 text-[2.5rem]" />
              </a>
              <a href="#">
                <BsInstagram className="text-white pt-4 text-[2.5rem]" />
              </a>
              <a href="#">
                <FiTwitter className="text-white pt-4 text-[2.5rem]" />
              </a>
              <a href="#">
                <RiYoutubeLine className="text-white pt-3 text-[2.7rem]" />
              </a>
            </section>
          </div>
        </center>{" "}
      </div>
    </div>
  );
};

export default Footer;
