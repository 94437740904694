import React from 'react';
import css from "./Contact.module.scss"
import ContactForm from '../../components/Contact/contactform';

import {CiLocationArrow1} from "react-icons/ci"
import {CiPhone} from "react-icons/ci"
import {CiMail} from "react-icons/ci"

const Contact = () => {
  return (
    <div className='mt-20'>

        <div className={css.container}>
            <center>
                <h1 className={css.heading}>
                    Contact Us
                </h1>
                <p className={css.text}>
                    Get ultimate solution for modern wellness management
                </p>
            </center>
        </div>

        <div className={css.container2}>
            <section className={css.container3}>
            <h1 className={css.heading}>
                    Get in touch
                </h1>
                <div className={css.flexfiv}>
                <CiLocationArrow1 className='text-[2rem] text-[#6c7d02]'/> <a>A- 516, Ratnaakar 9 Square,
Opp ITC Narmada, Keshavbag,
Vastrapur, Ahmedabad- 380015.</a>
                </div>
                <div className={css.flexfiv}>
                <CiPhone className='text-[2rem] text-[#6c7d02]'/> <a className='pt-2' href="tel:+1234567890">+91 998 977 3391</a>
                </div><div className={css.flexfiv}>
                <CiMail className='text-[2rem] text-[#6c7d02]'/> <a className='pt-2' href="mailto:info@reveation.com">info@reveation.com </a>
                </div>
            </section>
            <section className={css.container4}>
                <ContactForm/>
            </section>
        </div>

        <div className={css.con3}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4366.598175754897!2d72.53059677299672!3d23.029031255486657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84cf45606dfd%3A0x1c7fb7c03fb46550!2sRatnaakar%20Nine%20Square!5e0!3m2!1sen!2sin!4v1686904246886!5m2!1sen!2sin" width="600" height="450" 
        className=' w-full'
        
        allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        
    </div>
  )
}

export default Contact