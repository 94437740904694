// import React from "react";
// import "./Third.css";
// import image from "../../../assets/images/H-Third.png";

// const Third = () => {
//   return (
//     <div className="relative md:mt-36 sm:mt-28 lg:mt-44 xl:mt-64">
//       <center>
//         <section className="Third-heading">
//           simplifies the daily operations of a wellness center,
//         </section>
//       </center>

//       <div className="third-parent">
//         <section className="third-left">
//           <div className="card">
//             <section className="card-heading">Front desk</section>
//             <p className="card-text">
//               This module handles the client-facing functions of the wellness
//               center, such as reception, check-in, and booking appointments.
//             </p>
//           </div>

//           <div className="card">
//             <section className="card-heading">Guest</section>
//             <p className="card-text">
//               This module maintains a database of guests, their contact
//               information, preferences, and booking history.
//             </p>
//           </div>

//           <div className="card">
//             <section className="card-heading">Housekeeping</section>
//             <p className="card-text">
//               This module tracks the inventory and usage of supplies and
//               equipment used for housekeeping and cleaning, as well as
//               housekeeping staff schedules.
//             </p>
//           </div>
//         </section>
//         <section className="third-right">
//           <div className="card">
//             <section className="card-heading">Reservation</section>
//             <p className="card-text">
//               This module manages the reservations and scheduling of services
//               and treatments offered by the wellness center.
//             </p>
//           </div>
//           <div className="card">
//             <section className="card-heading">
//               Daily Schedules for Therapies
//             </section>
//             <p className="card-text">
//               This module provides a view of the daily schedules for therapists
//               and practitioners, along with their availability and appointment
//               bookings.
//             </p>
//           </div>
//           <div className="card">
//             <section className="card-heading">Inventory</section>
//             <p className="card-text">
//               This module manages the inventory of products and supplies used in
//               the wellness center, such as skincare products, supplements, and
//               equipment.
//             </p>
//           </div>
//         </section>
//       </div>
//       <img src={image} className="w-[60%] mx-[20%] mt-24" />
//     </div>
//   );
// };

// export default Third;

import React, { useState,useEffect } from 'react';
import css from './Slider.module.scss'; // Import SCSS module

import{IoIosArrowRoundBack} from "react-icons/io"
import{IoIosArrowRoundForward} from "react-icons/io"

import slide1 from "../../../assets/images/Dashboard-cuate 1.png"
import slide2 from "../../../assets/images/Reservation.png"
import slide3 from "../../../assets/images/Guest.png"
import slide4 from "../../../assets/images/Daily Schedules for Therapies.png"
import slide5 from "../../../assets/images/Housekeeping.png"
import slide6 from "../../../assets/images/Inventory.png"

const Slider = () => {
  const slides = [
    {
      id: 1,
      text: 'This module handles the client-facing functions of the wellness center, such as reception, check-in, and booking appointments.',
      heading:"Front desk",
      image: slide1
    },
    {
      id: 2,
      text: 'This module manages the reservations and scheduling of services and treatments offered by the wellness center.',
      heading:"Reservation",

      image: slide2
    },
    {
      id: 3,
      text: 'This module maintains a database of guests, their contact information, preferences, and booking history.',
      heading:"Guest",

      image: slide3
    },
    {
      id: 4,
      text: 'This module provides a view of the daily schedules for therapists and practitioners, along with their availability and appointment bookings.',
      heading:"Daily Schedules for Therapies",

      image: slide4
    },
    {
      id: 5,
      text: 'This module provides a view of the daily schedules for therapists and practitioners, along with their availability and appointment bookings.',
      heading:"Housekeeping",
      image: slide5

    }, {
      id: 6,
      text: 'This module manages the inventory of products and supplies used in the wellness center, such as skincare products, supplements, and equipment.',
      heading:"Inventory",

      image: slide6
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { text, image ,heading,id} = slides[currentSlide];

  return (<>
     
         <section className={css.texthead} >
           simplifies the daily operations of a wellness center
        </section>
    
    <div className={css.container}> {/* Use the SCSS module for the container class */}
 

<div className={css.containerleft}>
  
<div className={css.heading}>{heading}</div> 

<div className={css.text}>{text}</div> 



<div className={css.controls}> {/* Use the SCSS module for the controls class */}
        <div onClick={goToPreviousSlide} className={css.prev}><IoIosArrowRoundBack/></div>
        <div onClick={goToNextSlide} className={css.next}><IoIosArrowRoundForward/></div>
      </div>


</div>
<div className={css.containerright}>
<div className={css.image}>
        <img src={image} alt="Slider Image" />
      </div>
</div>


{/* Use the SCSS module for the text class */}

    </div>
    
    <center>
        <span>
          {id}
        </span>
        /{slides.length}
      </center>
</>
  );
};

export default Slider;

